/* eslint-disable */
		

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2hz0XgxZu",
    "aws_user_pools_web_client_id": "7fbt6tu6e17ajka6mci3igsoas",
    "graphqlEndpoint":"https://c6diaqlyznhcdg6pljfdt5ctne.appsync-api.us-east-1.amazonaws.com/graphql",
    "user_uploads_bucket_name":"prod-bookmerlincicd8-useruploadedcontents3bucket2-ztphauig0dt5",
    "identity_pool_id" :"us-east-1:e2c7bca7-a682-4a0d-8f70-997f17084bf9",
    "user_uploads_bucket_region":"us-east-1",
    "common_content_bucket_name":"prod-bookmerlincicd8-commoncontents3bucket03b3cbc-c0o6zgqlh8q3"
    
};
export default awsmobile;
